<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">Kundensupport</span>
			</h3>
		</div>
		<div class="card-body py-3">
			<div class="col-12 col-md-6">
				<b-form-group v-if="hasPermission('super.admin')" class="col-12 col-md-6" label="Unternehmen auswählen">
					<b-select v-model="company" :options="companies" text-field="name" value-field="id"></b-select>
				</b-form-group>
			</div>
			<div class="col-12 col-md-6">
				<b-button v-if="hasPermission('super.admin')" variant="success" v-on:click="onUpdate">Speichern</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import {SET_ACTION} from "@/core/services/store/actions.module";
import ApiService from "@/core/services/api.service";
import {PRINOR_PROCESSES} from "@/core/services/actions.service";
import {PRINOR_TOASTS} from "@/core/services/toast.service";
import {mapGetters} from "vuex";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";

export default {
	name: 'SuperCustomer',
	data() {
		return {
			saveButtonId: '',
			company: null,
			companies: [],
		};
	},
	computed: {
		...mapGetters(['pageProcesses']),
		companyList: function() {
			let list = this.companies.filter(value => { return value.organization_id === this.customer.organization.id });
			return list;
		}
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: 'Super Administration',
			},
			{ title: 'Kundensupport' },
		]);
		// this.saveButtonId = PRINOR_ACTIONS.create(this, 'Speichern', this.onUpdate, 'success', '', false, !this.hasPermission('super.admin'));

		PRINOR_PROCESSES.isLoading();
		this.onGet();
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		selectOrganization: function(val) {
			let organization = this.organizations.filter(value => { return value.id === val; });
			if (organization.length === 1) {
				this.customer.organization.id = organization[0].id;
				this.customer.organization.name = organization[0].name;
				this.customer.organization.description = organization[0].description;

				if (this.companyList.length > 0) {
					this.selectCompany(this.companyList[0].id);
				}
			}
		},
		selectCompany: function(val) {
			let company = this.companies.filter(value => { return value.id === val; });
			if (company.length === 1) {
				this.customer.company.id = company[0].id;
				this.customer.company.name = company[0].name;
				this.customer.company.description = company[0].description;
			}
		},
		onGet: function() {
			this.isLoading = true;

			this.get().then(data => {
				this.companies = data.data;
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('super/companies').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		onUpdate() {
			PRINOR_PROCESSES.isUpdating();
			this.update();
		},
		update() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('super/company/' + this.company).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
							window.location.reload();
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}

						resolve(data.data);
					}
				});
			});
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
